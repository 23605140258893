import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom"
import { Fragment } from "react";
import Counter from './Counter';
import ClientsFeedback from "../Page/ClientsFeedback";
import Hero1 from "../Page/Hero1";
import Layout from "../../Component/layout/Layout";
const Homepage = () => {
  const [isCrmHovered, setIsCrmHovered] = useState(false);
  const [isIbHovered, setIsIbHovered] = useState(false);
  const [isForexHovered, setIsForexHovered] = useState(false);
  const [isWebHovered, setIsWebHovered] = useState(false);
  return (
    <Fragment>
      <Helmet>
        <Link rel="shortcut icon" href="/assets/images/favicon1.png" type="image/x-icon" />
      </Helmet>
      <Layout header={1} className="home-one" footer={1}>

        <Hero1 />


        {/* <section className="feature-area-five bgc-lighter pt-100 pb-70">
          <div className="container">
            <div className="section-title text-center mb-10 wow fadeInUp delay-0-2s">
              <span className="sub-title mb-1">
                OUR SERVICES
              </span>
              <h2><b>Forex Solutions</b></h2>
            </div>
            <div className="row row-cols-xl-6 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 justify-content-center">
              <div className="col wow fadeInUp delay-0-2s">
                <div className="feature-item-five">
                  <i className="flaticon-responsive" />
                  <h5>
                    <Link to="/service-details">
                      <a className='service-title'>Forex Trader Room</a>
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-3s">
                <div className="feature-item-five">
                  <i className="flaticon-feature" />
                  <h5>
                    <Link to="/service-details">
                      <a className='service-title'>Multilevel IB
                      </a>
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-4s">
                <div className="feature-item-five">
                  <i className="flaticon-aim" />
                  <h5>
                    <Link to="/service-details">
                      <a className='service-title'>Forex Web Design</a>
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-5s">
                <div className="feature-item-five">
                  <i className="flaticon-seo" />
                  <h5>
                    <Link to="/service-details">
                      <a className='service-title'>CRM Solutions</a>
                    </Link>
                  </h5>
                </div>
              </div>

            </div>
          </div>
        </section> */}
        <section className="bgc-lighter section_padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex flex-column justify-content-center">
                {/* <h5 style={{ color: '#14AEE5' }} ><b>OUR SERVICES</b></h5> */}
                <h2 className='sub_heading mb-lg-4 mb-3'>Power Your Forex Business Using LogicLink Technology Services Fzco!
                </h2>
                <p className="sub_paragraph">LogicLink Technology Services Fzco is the place where Forex excellence meets innovation. Welcome! From professional Forex websites to high-volatility Synthetic Indices, simple CRM, and next-level Social Trading, we provide your business with modern technology. With LogicLink Technology Services Fzco revolutionary solutions, you can scale more quickly, trade more intelligently, and maintain an advantage over competitors. Let us collaborate to transform the Forex market!
                </p>
                <a href='/contact' className="theme-btn mt-lg-5 mt-4 w-50">
                  Get Touch
                  <i className="fas fa-angle-double-right" />
                </a>
              </div>

              <div className="col-xl-6">
                <div className='row'>
                  <div className="col-md-6">
                    <div className='mt-5' >
                      <div className="service-card">
                        {/* <i className="flaticon-responsive" /> */}
                        <div>
                          <img src="/assets/images/forextrader1.png" height={70} width={70} style={{ objectFit: 'contain' }} className="mb-4 absolute top-0 left-0" alt="img" />
                        </div>
                        <p className='heading_h5 fw-semibold'>Next-Level Forex CRM</p>
                        <p >
                          Effortlessly simplify, customize, and expand your broking!
                        </p>

                      </div>
                    </div>
                    <div onMouseEnter={() => setIsWebHovered(true)} onMouseLeave={() => setIsWebHovered(false)} >
                      <div className="feature-item-five card-text ">
                        {/* <i className="flaticon-aim" /> */}
                        <div>
                          {
                            isWebHovered ?
                              (<img src="/assets/images/forexwebdesign1.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />) :
                              (<img src="/assets/images/forexwebdesign.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />)
                          }
                        </div>
                        <h5 className='mb-3'>
                          <Link to="/service-details">
                            <a className='service-title heading_h5'> Synthofeed is a Smarter Way to Trade
                            </a>
                          </Link>
                        </h5>
                        <p className="card-text">
                          Synthetic indices with high volatility for maximum profits!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div onMouseEnter={() => setIsIbHovered(true)} onMouseLeave={() => setIsIbHovered(false)} >
                      <div className="feature-item-five card-text">
                        {/* <i className="flaticon-feature" /> */}
                        <div>
                          {
                            isIbHovered ?
                              (<img src="/assets/images/multilevelib1.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />) :
                              (<img src="/assets/images/multilevelib.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />)
                          }
                        </div>
                        <h5 className='mb-3'>
                          <Link to="/service-details">
                            <a className='service-title heading_h5'> Establish Your Forex Empire
                            </a>
                          </Link>
                        </h5>
                        <p className="card-text">
                          Websites that are visually appealing and that attract clients!
                        </p>
                      </div>
                    </div>
                    <div className='mt-4'>
                      <div className="service-card">
                        {/* <i className="flaticon-seo" /> */}
                        <div>
                          <img src="/assets/images/crmsolution1.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />
                        </div>
                        <p className='fw-semibold heading_h5'>Social Trading Made Simple
                        </p>
                        <p className='fs-6'>
                          Instantly grow, trade, and copy with the most successful investors!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Core Feature end */}
        {/* About Us Area start */}
        <section className="section_padding">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 d-flex justify-content-center">
                <div className="about-image-one bg-squire-shape wow fadeInUp delay-0-2s">
                  <img src="/assets/images/blog/aboutss.png" alt="About us" />
                  <img
                    className="image-left"
                    src="/assets/images/shapes/image-left.png"
                    alt="shape"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content-one wow fadeInUp delay-0-4s">
                  <div className="section-title mb-10">
                    {/* <span className="sub-title mb-1">
                      ABOUT US
                    </span> */}
                    <h2 className='sub_heading mb-lg-4 mb-3'>Boost Your Forex Enterprise!
                    </h2>
                  </div>
                  <div className="content">
                    {/* <h4>Company Mission</h4> */}
                    <p className='sub_paragraph'>
                      LogicLink Technology Services Fzco solutions are capable of raising your Forex business to new heights. We offer an extensive variety of services, including custom Forex websites, high-volatility Synthetic Indices, and a simple Forex CRM, as well as intelligent Social Trading tools, to help you scale, engage clients, and optimise profits. Do better business and grow faster to stay compared to the competition.
                    </p>
                    {/* <Link href="/pricing"> */}
                    <Link to='/contact' className="theme-btn mt-md-4 mt-3">
                      Get In Touch
                      <i className="fas fa-angle-double-right" />
                    </Link>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bgs-cover section_padding rel z-1">
          <div className="container">
            <div className="row g-5 justify-content-between align-items-center">
              <div className="col-lg-7">
                <div className="statistics-three-content rmb-65 wow fadeInRight delay-0-2s">
                  <div className="row justify-content-center justify-content-xl-start align-items-center">
                    <div className="col-xl-9">
                      <div className="mb-lg-5 mb-4">
                        {/* <span className="sub-title mb-1">
                          COMPANY STATISTICS
                        </span> */}
                        <h2 className='sub_heading mb-lg-4 mb-3'>We’ve some achievement from global partners</h2>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                      <div className="counter-item style-two counter-text-wrap wow fadeInRight delay-0-3s">
                        <i className="flaticon-startup" />
                        <Counter end={500} />
                        <span className="counter-title">Satisfied Clients</span>
                        {/* <p>On the other denonce with righteous indin</p> */}
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                      <div className="counter-item style-two counter-text-wrap wow fadeInRight delay-0-5s">
                        <i className="flaticon-global" />
                        <Counter end={90} />
                        <span className="counter-title">Repeat Business</span>
                        {/* <p>On the other denonce with righteous indin</p> */}
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                      <div className="counter-item style-two counter-text-wrap wow fadeInRight delay-0-7s">
                        <i className="flaticon-rating" />
                        <Counter end={10} />
                        <span className="counter-title">Years of Experience</span>
                        {/* <p>On the other denonce with righteous indin</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="statistics-three-image bg-squire-shape mb-30 wow fadeInLeft delay-0-3s">
                  <img
                    src="assets/images/about/statictics.jpg"
                    alt="Statictics"
                  />
                  <img
                    className="image-right"
                    src="assets/images/shapes/image-right.png"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="design-feature-area overflow-hidden section_padding text-white bgc-black-with-lighting rel z-1">
          <div className="container">
            <div className="section-title text-center mb-30 wow fadeInUp delay-0-2s">
              {/* <span className="sub-title mb-1">LET'S GO LIVE</span> */}
              <h2 className='sub_heading mb-lg-5 mb-3'>Enhancing Forex and Trading Solutions
              </h2>
            </div>
            <div className="row no-gap align-items-center">
              <div className="col-lg-3">
                <div className="feature-left">
                  <div className="row g-3">
                    <div className="col-lg-12 col-sm-6">
                      <div className="service-item style-three wow fadeInRight delay-0-2s">
                        <div className="icon">
                          <i className="flaticon-design" />
                        </div>
                        <div className="content">
                          <h4 className='heading_h4'>
                            <a>Neptune CRM</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6">
                      <div className="service-item style-three wow fadeInRight delay-0-3s">
                        <div className="icon">
                          <i className="flaticon-web-page" />
                        </div>
                        <div className="content">
                          <h4 className='heading_h4'>
                            <a>Synthofeed </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-middle rmt-30">
                  <div className="image wow fadeInUp delay-0-2s">
                    <img
                      className="circle-text"
                      src="assets/images/features/Social Trading, Indices, Websites, and CRM_.png"
                      alt="Text"
                    />
                    <img
                      className="round"
                      src="assets/images/features/feature-middle.png"
                      alt="Feature Middle"
                    />
                  </div>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <div className="service-item style-three wow fadeInUp delay-0-3s">
                        <div className="icon">
                          <i className="flaticon-online" />
                        </div>
                        <div className="content">
                          <h4 className='heading_h4'>
                            <a>Forex Websites</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-md-0 mb-3">
                      <div className="service-item style-three mt-30 wow fadeInUp delay-0-4s">
                        <div className="icon">
                          <i className="flaticon-web-programming" />
                        </div>
                        <div className="content">
                          <h4 className='heading_h4'>
                            <a>Neptune Copier</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="feature-right">
                  <div className="row g-3">
                    <div className="col-lg-12 col-sm-6">
                      <div className="service-item style-three mt-100 wow fadeInLeft delay-0-2s">
                        <div className="icon">
                          <i className="flaticon-graphic-design" />
                        </div>
                        <div className="content">
                          <h4 className='heading_h4'>
                            <a>Secure Transactions</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6">
                      <div className="service-item style-three wow fadeInLeft delay-0-3s">
                        <div className="icon">
                          <i className="flaticon-user-experience" />
                        </div>
                        <div className="content">
                          <h4 className='heading_h4'>
                            <a>24/5 Support</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="design-feature-shapes">
            <img
              className="shape dots"
              src="assets/images/shapes/slider-dots.png"
              alt="Shape"
            />
            <img
              className="shape wave-line"
              src="assets/images/shapes/feature-wave-line.png"
              alt="Shape"
            />
          </div>
        </section >
        <section className="feature-area-five bgc-lighter section_padding">
          <div className="container">
            <div className="section-title text-center mb-10 wow fadeInUp delay-0-2s">
              {/* <span className="sub-title mb-1">OUR BENEFITS</span> */}
              <h2 className='sub_heading mb-lg-5 mb-4'>Why Choose Us?</h2>
            </div>
            <div className="row md:g-5 g-3 row-cols-xl-2 row-cols-sm-2 row-cols-1 justify-content-center">
              <div className="col wow fadeInUp delay-0-2s service-title ">
                <div className="feature-item-five user-select-none">
                  <i className="flaticon-responsive" />
                  <h5 className='mb-xl-4 mb-3'>
                    <a className='service-title heading_h6 user-select-none'>Advanced Trading Solutions
                    </a>
                  </h5>
                  <p className="sub_paragraph blog_title_clamp">Synthetic indices, social trading, and innovative CRM—all the tools necessary to scale your Forex business profitably and efficiently.</p>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-3s service-title ">
                <div className="feature-item-five">
                  <i className="flaticon-feature" />
                  <h5 className='mb-xl-4 mb-3'>
                    <a className='service-title heading_h6'>Trusted and Secured
                    </a>
                  </h5>
                  <p className="sub_paragraph blog_title_clamp">Fast transactions, strong authentication, and smooth platform integrations guarantee that your broking operates efficiently with the highest level of security.
                  </p>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-4s service-title ">
                <div className="feature-item-five">
                  <i className="flaticon-aim" />
                  <h5 className='mb-xl-4 mb-3'>
                    <a className='service-title heading_h6'>Customisable and Flexible
                    </a>
                  </h5>
                  <p className="sub_paragraph blog_title_clamp">Brokers are provided with customized products that are designed to meet their specific business requirements. These solutions include potent tools, flexible features, and custom branding.
                  </p>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-5s service-title ">
                <div className="feature-item-five">
                  <i className="flaticon-seo" />
                  <h5 className='mb-xl-4 mb-3'>
                    <a className='service-title heading_h6'>Expert Support Available 24/5
                    </a>
                  </h5>
                  <p className="sub_paragraph blog_title_clamp">Our team is committed to providing you with quick, professional support at all times, thus helping you to optimise your trading success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="work-process-area section_padding pt-0 rel z-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="section-title text-center mb-10 wow fadeInUp delay-0-2s pt-4">
                  {/* <span className="sub-title mb-1 mt-5">WORKING PROCESS</span> */}
                  <h2 className='sub_heading mb-lg-5 mb-4'>How does we works</h2>
                </div>
              </div>
            </div>
            <div className="work-process-wrap rel z-1">
              <div className="row justify-content-between">
                <div className="col-xl-3 col-lg-5 col-sm-6">
                  <div className="work-process-item mt-30 wow fadeInUp delay-0-2s">
                    <div className="icon">
                      <span className="number">01</span>
                      <i className="flaticon-optimization" />
                    </div>
                    <h4 className='heading_h5'>Strategic Analysis</h4>
                    <p className='sub_paragraph'>
                      Modified strategies for achieving trading success.
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 col-sm-6">
                  <div className="work-process-item wow fadeInUp delay-0-4s">
                    <div className="icon">
                      <span className="number">02</span>
                      <i className="flaticon-link" />
                    </div>
                    <h4 className='heading_h5'>Build Efficiently</h4>
                    <p className='sub_paragraph'>
                      Platforms that are user-friendly, scalable, and secure.
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 col-sm-6">
                  <div className="work-process-item mt-55 wow fadeInUp delay-0-6s">
                    <div className="icon">
                      <span className="number">03</span>
                      <i className="flaticon-data" />
                    </div>
                    <h4 className='heading_h5'>Easily Integrate</h4>
                    <p className='sub_paragraph'>
                      Ensure smooth integration with the most important trading systems.
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 col-sm-6">
                  <div className="work-process-item mt-45 wow fadeInUp delay-0-8s">
                    <div className="icon">
                      <span className="number">04</span>
                      <i className="flaticon-star" />
                    </div>
                    <h4 className='heading_h5'>Continuous Support</h4>
                    <p className='sub_paragraph'>
                      Qualified support is available 24/5 to help your development.
                    </p>
                  </div>
                </div>
              </div>
              <div className="work-process-shape">
                <img
                  src="assets/images/shapes/worp-process-step.png"
                  alt="Shape"
                />
              </div>
            </div>
          </div>
        </section>

        <ClientsFeedback />

        <section className="contact-area overflow-hidden section_padding bgc-black-with-lighting rel z-1 m">
          <div className="container">
            <div className="row lg:g-5 g-3 justify-content-between ">
              <div className="col-xl-6 col-lg-6 md:p-1 p-2">
                <div className="contact-info-area text-white  wow fadeInLeft delay-0-2s">
                  <div className="section-title mb-xl-5 mb-4">
                    <h2 className='sub_heading'>
                      Have any project on mind! feel free contact <br className='d-lg-none d-md-block d-none' /> with us or{" "}
                      <span>say hello</span>
                    </h2>
                  </div>
                  <div className="contact-info-wrap">
                    <div className="contact-info-item">
                      <div className="icon">
                        {/* <i className="fal fa-map-marker-alt" /> */}
                        <img src='/assets/images/location.svg' alt='' className='svg-icons' />
                      </div>
                      <div className="content ">
                        <span className="heading_h6" >Location</span>
                        <span className="" >
                          <div className='sub_paragraph mt-1'>38507/001, IFZA Business Park, <br className='d-xl-none d-lg-block d-md-none ' /> Dubai Silicon, Oais, Dubai, UAE.</div></span>
                      </div>
                    </div>
                    <div className="contact-info-item">
                      <div className="icon">
                        {/* <i className="far fa-envelope-open-text" /> */}
                        <img src='/assets/images/email.svg' alt='' className='svg-icons' />
                      </div>
                      <div className="content">
                        <span className="heading_h6">Email Address</span>
                        <b className="text  mt-1" >
                          <a href="mailto:office@logiclinkfzco.com" className='sub_paragraph'>
                            office@logiclinkfzco.com
                          </a>
                        </b>
                      </div>
                    </div>
                    <div className="contact-info-item">
                      <div className="icon">
                        {/* <i className="far fa-phone" /> */}
                        <img src='/assets/images/call.svg' alt='' className='svg-icons' />
                      </div>
                      <div className="content">
                        <span className="heading_h6">Phone No</span>
                        <b className="text mt-1" >
                          <a href="callto:+971 50 786 6593" className='sub_paragraph'>+971-50-1963589</a>
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  id="contact-area-form"
                  className="contact-area-form text-center wow fadeInRight delay-0-2s"
                  name="contact-area-form"
                  action="#"
                  method="post"
                >
                  <h2 className='sub_heading mb-4'>Send us Message</h2>
                  <input
                    type="text"
                    id="full-name"
                    name="full-name"
                    className="form-control"
                    defaultValue=""
                    placeholder="Full Name"
                    required=""
                  />
                  <input
                    type="email"
                    id="blog-email"
                    name="blog-email"
                    className="form-control"
                    defaultValue=""
                    placeholder="Email Address"
                    required=""
                  />
                  <input
                    type="text"
                    id="website"
                    name="website"
                    className="form-control"
                    defaultValue=""
                    placeholder="Website"
                    required=""
                  />
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows={5}
                    placeholder="Write Message"
                    required=""
                    defaultValue={""}
                  />
                  <button type="submit" className="theme-btn">
                    Send messages <i className="fas fa-angle-double-right" />
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="contact-shapes">
            <img
              className="shape circle"
              src="assets/images/shapes/slider-dots.png"
              alt="Shape"
            />
            <img
              className="shape dots"
              src="assets/images/shapes/contact-dots.png"
              alt="Shape"
            />
            <img
              className="shape wave-line"
              src="assets/images/shapes/contact-wave-line.png"
              alt="Shape"
            />
          </div>
        </section>
        <section className='section_padding'>
          <div className='container'>
            <h2 className='sub_heading mb-xl-5 mb-4'>Our Latest Blog</h2>
            <div className='row g-4 justify-content-center'>
              <div className='col-lg-4 col-md-6'>
                <Link to="/blog/blog1" className="blog-card-link">
                  <div className='blog-card'>
                    <div>
                      <img src="assets/images/blog/blog1.png" alt="Blog" className='blog-card-image ' />
                    </div>
                    <div className="blog-card-content">
                      <div className="blog-card-header">
                        <span className="date blog-card-date">
                          <i className="far fa-calendar-alt me-2" /> 25 March 2024
                        </span>
                        <a href="#" className="blog-card-link">Read Blog  →</a>
                      </div>
                      <h5 className='heading_h6'>
                        <span className='blog_title_clamp'>Changing Forex Trading: How LogicLink Technology Services Fzco Empowers Brokers & Traders
                        </span>
                      </h5>
                      <div className="post-by blog-card-title">
                        <span className="blog_line_clamp">In the fast-paced world of Forex trading, the appropriate instruments can be the deciding factor. Innovative solutions that assist brokers and traders in maintaining an advantage are offered by LogicLink Technology Services Fzco.</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6">
                <Link to="/blog/blog2" className="blog-card-link">
                  <div className="blog-card">
                    <div>
                      <img
                        src="assets/images/blog/blog2.png"
                        alt="Blog"
                        className="blog-card-image"
                      />
                    </div>
                    <div className="blog-card-content">
                      <div className="blog-card-header">
                        <span className="date blog-card-date">
                          <i className="far fa-calendar-alt me-2" /> 12 June 2024
                        </span>
                        <span className="blog-card-readmore">Read Blog →</span>
                      </div>
                      <h5 className="heading_h6">
                        <span className="blog_title_clamp">
                          The Future of Forex Trading: Smarter Solutions for Traders and Brokers
                        </span>
                      </h5>
                      <div className="post-by blog-card-title">
                        <span className="blog_line_clamp">
                          The Forex market is undergoing a rapid evolution, necessitating more than just experience to remain competitive. It necessitates advanced technology, automation, and a seamless user experience.
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className='col-lg-4 col-md-6'>
                <Link to="/blog/blog3" className="blog-card-link">
                  <div className='blog-card'>
                    <div>
                      <img src="assets/images/blog/blog3.png" alt="Blog" className='blog-card-image ' />
                    </div>
                    <div className="blog-card-content">
                      <div className="blog-card-header">
                        <span className="date blog-card-date">
                          <i className="far fa-calendar-alt me-2" />20 July 2024
                        </span>
                        <a href="#" className="blog-card-link">Read Blog  →</a>
                      </div>
                      <h5 className='heading_h6'>
                        <span className='blog_title_clamp'> Smart Solutions for Brokers and Traders:
                          Changing Forex Trading.
                        </span>
                      </h5>
                      <div className="post-by blog-card-title">
                        <span className="blog_line_clamp">In order to remain competitive, brokers and traders require state-of-the-art tools, automation, and seamless trading experiences, as the Forex industry is expanding at an unprecedented rate. At LogicLink Technology Services Fzco, we specialise in forex technology solutions that enhance efficiency, security, and profitability.
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout >
    </Fragment >
  );
};
export default Homepage;
