import React from 'react';
import { Link } from "react-router-dom"
import Slider from "react-slick";

const Hero1 = () => {
  const props = {
    infinite: true,
    arrows: false,
    dots: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    slidesToScroll: 1,
    slidesToShow: 1,
  };
  return (
    <section
      id="home"
      className="main-slider-area bgc-black-with-lighting rel z-1"
    >
      {/* <Slider {...props} className="main-slider-active">
        <div className="slider-item">
          <div className="container">'
            <div className='row g-5'>
              <div className='col-xl-7 lg:mb-0 mb-5'>
                <div className="slider-content">
                  <h1 style={{ letterSpacing: '1px' }} className='main_title mb-lg-5 mb-4'>Powering Forex Solutions with Advanced Technology </h1>
                  <span className="sub-title mb-5">
                    One-stop solution for CRM, Synthetic Indices, Forex Website Development, and Social Trading. Improve your trade using Logiclink's creative solutions.
                  </span>
                  <div className="slider-btns">
                    <Link to="/contact">
                      <a href='/contact' className="theme-btn">
                        Get Started Us <i className="fas fa-angle-double-right" />
                      </a>
                    </Link>
                    <Link to="/services">
                      <a className="theme-btn style-three btn-style">
                        Explore Services <i className="fas fa-angle-double-right" />
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-xl-5'>
                <div className="slider-video d-flex justify-content-center">
                  <img src="/assets/images/OBJECTS1.png" alt="Slider Video" className='img-fluid' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider> */}

      <div className='container'>
        <div className='row g-5 align-items-center'>
          <div className='col-lg-7'>
            <div className='mb-md-5 mb-4'>
              <h1 className='main_title mb-xl-5 mb-4'>Powering Forex Solutions with Advanced Technology </h1>
            </div>
            <span className="sub-title mb-xl-5 mb-4">
              One-stop solution for CRM, Synthetic Indices, Forex Website Development, and Social Trading. Improve your trade using LogicLink Technology Services Fzco creative solutions.
            </span>
            <div className="d-flex gap-3">
              <Link to="/contact">
                <a href='/contact' className="theme-btn">
                  Get Started Us <i className="fas fa-angle-double-right" />
                </a>
              </Link>
              <Link to="/services">
                <a className="theme-btn style-three btn-style">
                  Explore Services <i className="fas fa-angle-double-right" />
                </a>
              </Link>
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='d-flex justify-content-center'>
              <img src="/assets/images/OBJECTS1 1.png" alt="Slider Video" className='img-fluid hero_img ' />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="main-slider-dots" />
      </div>
      <div className="slider-shapes">
        <img
          className="shape dots one"
          src="assets/images/shapes/slider-dots.png"
          alt="Shape"
        />
        <img
          className="shape dots two"
          src="assets/images/shapes/slider-dots.png"
          alt="Shape"
        />
        <img
          className="shape wave-line"
          src="assets/images/shapes/slider-wave-line.png"
          alt="Shape"
        />
        <img
          className="shape circle"
          src="assets/images/shapes/slider-circle.png"
          alt="Shape"
        />
      </div>
    </section>
  );
};
export default Hero1;
