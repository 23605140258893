import React from 'react';
import { Link, useNavigate } from "react-router-dom"
import Layout from "../layout/Layout";
import PageBanner from "../layout/PageBanner";
const Blog = () => {
  const blogs = [
    {
      id: '1',
      title: 'Changing Forex Trading: How LogicLink Technology Services Fzco Empowers Brokers & Traders',
      excerpt: 'In the fast-paced world of Forex trading, the appropriate instruments can be the deciding factor...',
      image: 'assets/images/blog/blog1.png',
      date: '25 March 2024',
      slug: "blog1"

    },
    {
      id: '2',
      title: 'The Future of Forex Trading: Smarter Solutions for Traders and Brokers',
      excerpt: 'The Forex market is undergoing a rapid evolution, necessitating more than just experience to remain competitive. It necessitates advanced technology, automation, and a seamless user experience. We at LogicLink Technology Services Fzco offer state-of-the-art Forex solutions that are specifically designed to improve the operations of brokers and to equip traders with the necessary tools to achieve success.',
      image: 'assets/images/blog/blog2.png',
      date: '12 June 2024',
      slug: "blog2"

    },
    {
      id: '3',
      title: 'Smart Solutions for Brokers and Traders: Changing Forex Trading',
      excerpt: 'To remain competitive, brokers and traders require state-of-the-art tools, automation, and seamless trading experiences...',
      image: 'assets/images/blog/blog3.png',
      date: '20 July 2024',
      slug: "blog3"

    },
    {
      id: '4',
      title: 'The Future of Forex Trading: Intelligent Tools for Traders and Brokers',
      excerpt: 'It is essential to maintain a competitive advantage by implementing efficient solutions and utilising cutting-edge technology...',
      image: 'assets/images/blog/blog4.png',
      date: '25 March 2024',
      slug: "blog4"
    },
    {
      id: '5',
      title: 'The Importance of CRM in Forex Trading: Why Every Broker Must Implement It',
      excerpt: 'In the fiercely competitive Forex market, brokers must maintain a competitive edge by utilising intelligent technology...',
      image: 'assets/images/blog/blog5.png',
      date: '25 March 2024',
      slug: "blog5"

    },
    {
      id: '6',
      title: 'The Importance of an Effective Payment Gateway System for Forex Brokers',
      excerpt: 'The foundation of any successful Forex broking is a secure and seamless payment gateway. A platform must provide traders with rapid, reliable, and hassle-free deposit and withdrawal options in order to establish trust. Brokers are at risk of losing potential clients as a result of transaction delays and security concerns in the absence of a robust payment system.',
      image: 'assets/images/blog/blog6.png',
      date: '25 March 2024',
      slug: "blog6"
    },
  ];
  const navigate = useNavigate();

  return (
    <Layout>
      <PageBanner
        titleHtml={`Blog Grid <span>View</span>`}
      // titleText="Blog Grid"
      />
      <section className="section_padding">
        <div className="container">
          <div className="row g-4">
            {blogs.map((blog) => (
              <div className="col-lg-4 col-md-6" key={blog.id} onClick={() => navigate(`/blog/${blog.slug}`)}>
                <div className="blog-card">
                  <div>
                    <img src={blog.image} alt="Blog" className="blog-card-image" />
                  </div>
                  <div className="blog-card-content">
                    <div className="blog-card-header">
                      <span className="date blog-card-date">
                        <i className="far fa-calendar-alt me-2" /> {blog.date}
                      </span>
                      <a href='#' className="blog-card-link mb-0 ">
                        Read Blog →
                      </a>
                    </div>
                    <h5 className="heading_h6 ">
                      <span className='blog_title_clamp'>{blog.title}</span>

                    </h5>
                    <div className="post-by blog-card-title">
                      <span className="blog_line_clamp">{blog.excerpt}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Blog Grid Area end */}
    </Layout>
  );
};
export default Blog;
