import { useParams } from "react-router-dom";
import Blog1 from "../Blogs/Blog1";
import Blog2 from "../Blogs/Blog2";
import Blog3 from "../Blogs/Blog3";
import Blog4 from "../Blogs/Blog4";
import Blog5 from "../Blogs/Blog5";
import Blog6 from "../Blogs/Blog6";


export default function BlogRenderer() {
    const { BlogDetails } = useParams();
    const toolComponents = {
        
        blog1: <Blog1 />,
        blog2: <Blog2 />,
        blog3: <Blog3 />,
        blog4: <Blog4 />,
        blog5: <Blog5 />,
        blog6: <Blog6 />,
    };

    return (
        <div>
            {toolComponents[BlogDetails]}
        </div>
    )
}
