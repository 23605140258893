import { useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import PageBanner from "../layout/PageBanner";
const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("Neptune CRM");
  const dropdownRef = useRef(null);
  const options = ["Neptune CRM", "Synthofeed", "Website for Foreign Exchange", "Neptune Copier", "Neptune Prop Firm (Coming Soon)"];
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);
  return (
    <Layout>
      <PageBanner titleHtml={`Conta<span>ct Us</span>`}
      //   titleText="Contact"
      />
      <section className="contact-us-page-area section_padding">
        <div className="container">
          <div className="row lg:g-5 g-4">
            <div className="col-lg-6">
              <div className="contact-content mb-4 wow fadeInRight delay-0-2s">
                <div className="section-title mb-10">
                  <span className="sub-title style-two mb-1">CONTACT US</span>
                  {/* <span className="sub-title mb-1">CONTACT US</span> */}
                  <h2 className="sub_heading">
                    Let’s Start New Project or work Together! Contact With us
                  </h2>
                </div>
                <p className="sub_paragraph">
                  Work with us to enhance your Forex enterprise! We are available to provide support with CRM, trading tools, and website solutions. Let us work together to develop a premium product—contact us today!
                </p>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  id="contactForm"
                  className="contactForm z-1 rel"
                >
                  <div className="row pt-15">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name" className="heading_h6">Full Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Michael C. Coleman"
                          required
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="email" className="heading_h6">Email Address</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="support@gmail.com"
                          required
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="phone_number" className="heading_h6">Phone Number</label>
                        <input
                          type="text"
                          id="phone_number"
                          name="phone_number"
                          className="form-control"
                          placeholder="+000 (123) 456 88"
                        />
                      </div>
                    </div>

                    {/* Custom Dropdown */}
                    <div className="col-md-6">
                      <div className="form-group position-relative" ref={dropdownRef}>
                        <label className="heading_h6">Select Requirements</label>
                        <div className="position-relative w-100">
                          <div
                            className="form-control d-flex justify-content-between align-items-center cursor-pointer"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <div>{selected}</div>
                            <span className="text-secondary">
                              <i className={`fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                            </span>
                          </div>

                          {isOpen && (
                            <ul
                              className="position-absolute w-100 bg-white border rounded shadow-sm list-unstyled"
                              style={{ top: "100%", left: 0, zIndex: 1050 }}
                            >
                              {options.map((option) => (
                                <li
                                  key={option}
                                  className="p-2 cursor-pointer hover:bg-light options"
                                  onClick={() => {
                                    setSelected(option);
                                    setIsOpen(false);
                                  }}
                                >
                                  {option}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="message" className="heading_h6">Write Message</label>
                        <textarea
                          name="message"
                          id="message"
                          className="form-control"
                          rows={4}
                          placeholder="Write Message"
                          required
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group lg:pt-4 pt-3 mb-0">
                        <button type="submit" className="theme-btn">
                          Send Message <i className="fas fa-angle-double-right" />
                        </button>
                        <div id="msgSubmit" className="hidden" />
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-info wow fadeInLeft delay-0-2s">
                <div className="contact-info-item style-two">
                  <div className="icon">
                    <i className="fal fa-map-marker-alt" />
                  </div>
                  <div className="content locations">
                    <span className="title">Location</span><br />
                    <p className="sub_paragraph mt-2" >
                      38507/001, IFZA Business Park, Dubai Silicon, Oais, Dubai, UAE.
                    </p>
                  </div>
                </div>
                <div className="contact-info-item style-two">
                  <div className="icon">
                    <i className="far fa-envelope-open-text" />
                  </div>
                  <div className="content">
                    <span className="title">email address</span>
                    <span className="text">
                      <a href="mailto:support@gmail.com" className="sub_paragraph mt-2">
                        office@logiclinkfzco.com
                      </a>
                    </span>
                  </div>
                </div>
                <div className="contact-info-item style-two">
                  <div className="icon">
                    <i className="far fa-phone" />
                  </div>
                  <div className="content">
                    <span className="title">Phone Number</span>
                    <p className="sub_paragraph mt-2">
                      +971-50-1963589 
                    </p>
                  </div>
                </div>
                <div className="follow-us">
                  <h4>Follow Us</h4>
                  <div className="social-style-two">
                    <a href="https://www.facebook.com/people/Logiclink/61573811963814/" target='_blank'>
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="https://www.linkedin.com/company/logiclink-technology-service-fzco/" target='_blank'>
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Us Page Area end */}
      {/* Our Location Address Area start */}
      {/* <section className="our-location-address-area rel z-1">
        <div className="container">
          <div className="row medium-gap justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="location-address-item wow fadeInUp delay-0-2s">
                <div className="top-part">
                  <img
                    src="assets/images/contact/location1.png"
                    alt="Location"
                  />
                  <h5>New York</h5>
                </div>
                <div className="bottom-part">
                  <ul>
                    <li>
                      <i className="fal fa-map-marker-alt" /> 55 Main Street,
                      New York
                    </li>
                    <li>
                      <i className="far fa-envelope-open-text" />{" "}
                      support@gmail.com
                    </li>
                    <li>
                      <i className="far fa-phone" /> +000 (123) 456 88
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-address-item wow fadeInUp delay-0-4s">
                <div className="top-part">
                  <img
                    src="assets/images/contact/location2.png"
                    alt="Location"
                  />
                  <h5>Australia Capital</h5>
                </div>
                <div className="bottom-part">
                  <ul>
                    <li>
                      <i className="fal fa-map-marker-alt" /> 55 Main Street,
                      New York
                    </li>
                    <li>
                      <i className="far fa-envelope-open-text" />{" "}
                      support@gmail.com
                    </li>
                    <li>
                      <i className="far fa-phone" /> +000 (123) 456 88
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-address-item wow fadeInUp delay-0-6s">
                <div className="top-part">
                  <img
                    src="assets/images/contact/location3.png"
                    alt="Location"
                  />
                  <h5>South Africa</h5>
                </div>
                <div className="bottom-part">
                  <ul>
                    <li>
                      <i className="fal fa-map-marker-alt" /> 55 Main Street,
                      New York
                    </li>
                    <li>
                      <i className="far fa-envelope-open-text" />{" "}
                      support@gmail.com
                    </li>
                    <li>
                      <i className="far fa-phone" /> +000 (123) 456 88
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Our Location Address Area end */}
      {/* Location Map Area Start */}
      {/* <div className="contact-page-map wow fadeInUp delay-0-2s">
        <div className="our-location">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m12!1m10!1m3!1d142190.2862584524!2d-74.01298319978558!3d40.721725351435126!2m1!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sbd!4v1663473911885!5m2!1sen!2sbd"
            style={{ border: 0, width: "100%" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div> */}
      {/* Location Map Area End */}
    </Layout>
  );
};
export default Contact;
