import React, { useState } from 'react';
import Layout from "../layout/Layout";
import PageBanner from "../layout/PageBanner";

const Services = () => {
  const [activeTab, setActiveTab] = useState("neptune-crm");

  return (
    <Layout>
      <PageBanner titleHtml={`Services`} />
      <section className='section_padding'>
        <div className='container'>
          <div className='section-title text-center wow fadeInUp delay-0-2s mb-xl-5 mb-lg-4 mb-3'>
            <p className="sub_heading mb-2">Our Services</p>
            <p className="sub_paragraph">
            LogicLink Technology Services Fzco is a company that specialise in the development of innovative solutions that are specifically designed for the financial trading and Forex sectors. The four main services we offer ”Neptune CRM, Synthofeed, Forex Website Development, Neptune Copier and the upcoming Neptune Prop Firm” are planned to optimise user experience, improve trading efficiency, and simplify operations.
            </p>
          </div>

          <div className="nav nav-pills overflow-auto text-nowrap mb-lg-4 mb-3" >
            <ul className="d-flex justify-content-center mx-auto p-2 list-unstyled mb-0 sub_paragraph">
              <li className="nav-item">
                <button className={`nav-link ${activeTab === "neptune-crm" ? "active" : ""}`} onClick={() => setActiveTab("neptune-crm")}>
                  Neptune CRM
                </button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === "synthofeed" ? "active" : ""}`} onClick={() => setActiveTab("synthofeed")}>
                  Synthofeed
                </button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === "forex-website" ? "active" : ""}`} onClick={() => setActiveTab("forex-website")}>
                  Website for Foreign Exchange
                </button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === "neptune-copier" ? "active" : ""}`} onClick={() => setActiveTab("neptune-copier")}>
                  Neptune Copier
                </button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === "prop-firm" ? "active" : ""}`} onClick={() => setActiveTab("prop-firm")}>
                  Neptune Prop Firm (Coming Soon)
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content p-3">
            {activeTab === "neptune-crm" && (
              <div className="row align-items-center gx-lg-5 gy-3">
                <div className=''>
                  <h2 className="heading_h5 fw-bold">Neptune CRM</h2>
                  <p className="sub_paragraph">
                    Neptune CRM is a Forex Customer Relationship Management (CRM) system that simplifies broker operations and increases trader engagement.
                  </p>
                </div>
                <div className="col-lg-6">
                  <img src="/assets/images/services/neptuncrm.png" alt="Neptune CRM" className="img-fluid" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h5 className="heading_h5 mb-lg-3 mb-3">Key Features</h5>
                  <ul className='ps-0'>
                    {[
                      "User-Friendly Interface: A CRM that is both simple and easy to navigate for brokers and traders.",
                      "Full Customization: Customise your CRM with your logo, colours, and brand identity to provide a consistent customer experience.",
                      "Integration with Popular Trading Platforms: Makes sure easy trade management by easily integrating with MT5, Vertex, and Leverate.",
                      "Live Account Management: Real-time monitoring of balances, open positions, and trading history.",
                      "Simple Deposits and Withdrawals: Secure, quick, and a range of payment options to ensure smooth transactions.",
                      "IB Management System: Efficiently monitor the performance, commissions, and referrals of Introducing Brokers (IBs).",
                      "Security & Privacy: Tested security and safety methods for protecting user data."
                    ].map((point, index) => (
                      <>
                        <li key={index} className="d-flex align-items-center mb-2 gap-3">
                          <img className="" src="/assets/images/services/Group-1.svg" />
                          {point}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {activeTab === "synthofeed" && (
              <div className="row align-items-centergx-lg-5 gy-3">
                <div className=''>
                  <h2 className="heading_h5">Synthofeed</h2>
                  <p className='heading_h4 mb-2'>What are synthetic indices?</p>
                  <p className="sub_paragraph">
                    Neptune CRM is a Forex Customer Relationship Management (CRM) system that simplifies broker operations and increases trader engagement.
                  </p>
                </div>
                <div className="col-lg-6">
                  <img src="/assets/images/services/synthofeed.png" alt="Neptune CRM" className="img-fluid" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h5 className="heading_h5 mb-lg-3 mb-3">Key Features and Benefits:
                  </h5>
                  <ul className='ps-0'>
                    {[
                      "AI-Based Index Creation: Dynamic indices are created using real-time market data, stock prices, indices, currency pairs, and cryptocurrencies.",
                      "High Market Volatility: Provides exposure to volatile assets, increasing trading opportunities.",
                      "24/5 Trading Availability: Synthetic indices provide 24/5 trading availability, unlike traditional markets.",
                      "Low Initial Investment: You can begin trading without having to invest a lot of money.",
                    ].map((point, index) => (
                      <>
                        <li key={index} className="d-flex align-items-center mb-2 gap-3">
                          <img className="" src="/assets/images/services/Group-1.svg" />
                          {point}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {activeTab === "forex-website" && (
              <div className="row align-items-center gx-lg-5 gy-3">
                <div className=''>
                  <h2 className="heading_h5"> Website for Foreign Exchange
                  </h2>
                  <p className="sub_paragraph">
                    Is your Forex firm in need of a strong web presence? Best user experience, safety, and engagement are the basis of our custom Forex website solutions.
                  </p>
                </div>
                <div className="col-lg-6">
                  <img src="/assets/images/services/forignexchange.png" alt="Neptune CRM" className="img-fluid" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h5 className="heading_h5 mb-lg-3 mb-3">Key Features:
                  </h5>
                  <ul className='ps-0'>
                    {[
                      "Relevant and Flexible Design: An carefully created, mobile-friendly website specifically designed for traders.  ",
                      "Live Forex Rates & Market Updates: Get real-time forex rates and market updates to make decisions based on it.",
                      "Client Dashboard and Login: This makes it easy for access to information about your account and trades.",
                      "Trading Resources & Tools: Market analysis tools, indicators, and instructional materials.",
                      "SEO-Optimized for Visibility: Increase your search engine ranking to attract more customers."
                    ].map((point, index) => (
                      <>
                        <li key={index} className="d-flex align-items-center mb-2 gap-3">
                          <img className="" src="/assets/images/services/Group-1.svg" />
                          {point}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {activeTab === "neptune-copier" && (
              <div className="row align-items-center gx-lg-5 gy-3">
                <div className=''>
                  <h2 className="heading_h5">Neptune Copier
                  </h2>
                  <p className="sub_paragraph">
                    Neptune Copier offers social trading services that allow users to effortlessly follow and mirror strategies from experts in trading.
                  </p>
                </div>
                <div className="col-lg-6">
                  <img src="/assets/images/services/neptuncopier.png" alt="Neptune CRM" className="img-fluid" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h5 className="heading_h5 mb-lg-3 mb-3">Key Features:
                  </h5>
                  <ul className='ps-0'>
                    {[
                      "Fund Manager & Investor Accounts: Users have the option to join in as either a Fund Manager (to provide strategies) or an Investor (to follow strategies).",
                      "Low Platform Fees: Start trading with minimal expenses and increase your profits.",
                      "Live Strategy Tracking: With just a few taps, you can view and mirror trades in real-time.",
                      "Automated Copy Trading: Automatically execute trades of top-performing traders in your account.",
                      "Various Trading Options: Follow signals and expert recommendations, mirror trading techniques, or copy full strategies.",
                      "Risk Management Features: Customise trade volumes, set limits, and place stop-loss orders for better risk management."
                    ].map((point, index) => (
                      <>
                        <li key={index} className="d-flex align-items-center mb-2 gap-3">
                          <img className="" src="/assets/images/services/Group-1.svg" />
                          {point}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {activeTab === "prop-firm" && (
              <div className="row align-items-center gx-lg-5 gy-3">
                <div className=''>
                  <h2 className="heading_h5"> Neptune Prop Firm (Coming Soon)
                  </h2>
                  <p className="sub_paragraph">
                    We are very happy to announce the development of Neptune Prop Firm, our soon to come proprietary trading solution. This solution has been designed to help traders in obtaining funded accounts and trading with institutional-grade capital.
                  </p>
                </div>
                <div className="col-lg-6">
                  <img src="/assets/images/services/commingsoon1.png" alt="Neptune CRM" className="img-fluid" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h5 className="heading_h5 mb-lg-3 mb-3">What is to be planned?
                  </h5>
                  <ul className='ps-0'>
                    {[
                      "Trade with Firm Capital: Receive access to huge trading funds without applying personal capital.",
                      "Funding and Monitoring Stages: Successfully complete structured trading challenges to be qualified for funded accounts.",
                      "Profit-Sharing Model: Keep a large portion of earnings while the company covers losses",
                      "Advanced Risk Management: Daily drawdown limits, leverage management, and real-time analytics. ",
                      "Access to Premium Tools: Advanced market analytics, expert coaching, and performance monitoring.",
                    ].map((point, index) => (
                      <>
                        <li key={index} className="d-flex align-items-center mb-2 gap-3">
                          <img className="" src="/assets/images/services/Group-1.svg" />
                          {point}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Services;
