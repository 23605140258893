import Layout from "../layout/Layout";
import PageBanner from "../layout/PageBanner";

export default function PrivacyPolicy() {
    return (
        <>
            <Layout>
                <PageBanner titleHtml={`Privacy Policy`} />
                <section className="section_padding">
                    <div className="container">
                        <div className="sub_paragraph">
                            <p>
                                At LogicLink Technology Service fzco, we are dedicated to the protection of your personal data and privacy. This Privacy Policy defines the methods by which we collect, use, retain, and secure your information when you access our website or utilise our services. The terms specified in this policy are accepted by you by accessing or using LogicLink Technology Service fzco.
                            </p>
                            <h5 className="heading_h5 fw-bold ">Information That We Collect</h5>
                            <p className="mb-lg-4 mb-3 ">The following categories of information may be gathered:
                            </p>
                            <h6 className="heading_h6  ">Personal Information
                            </h6>
                            <p className="mb-1">We may gather personal information, such as but not limited to: when you engage with our website.
                            </p>
                            <ul>
                                <li>Your complete name</li>
                                <li>Email address</li>
                                <li>Phone number</li>
                            </ul>
                            <p>Any additional information that you voluntarily disclose
                            </p>
                            <h6 className="heading_h6 mb-3">Technical and Usage Data
                            </h6>
                            <p className="mb-1">When you visit our website, we gather specific technical data, such as:
                            </p>
                            <ul>
                                <li>Internet Protocol address
                                </li>
                                <li>Browser brand and version</li>
                                <li>Operating system and device type
                                </li>
                                <li>Interaction patterns, time spent and pages visited
                                </li>
                                <li>Source of referral (e.g., the method by which you discovered our website)
                                </li>
                            </ul>
                            <h6 className="heading_h6 mb-3">Tracking Technologies and Cookies
                            </h6>
                            <p>In order to optimise our services, analyse site traffic, and enhance the user experience, we implement cookies and comparable monitoring technologies. Your browser settings are the means by which you can regulate your cookie preferences.
                            </p>

                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">The Way in Which We Utilise Your Information
                            </h5>
                            <p>The information that we have collected is utilised for the following purposes:
                            </p>
                            <ul>
                                <li>To administer and provide our services
                                </li>
                                <li>To ensure the secure processing of transactions and payments
                                </li>
                                <li>To keep you informed about the latest offers, updates, and support.
                                </li>
                                <li>To enhance the functionality and efficacy of our website To personalise your online experience
                                </li>
                                <li>In order to safeguard against fraud and guarantee security
                                </li>
                                <li>In order to satisfy legal and regulatory obligations
                                </li>
                                <li>Your personal information will never be sold or rented to third parties.
                                </li>
                            </ul>

                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">Our Approach to Data Security
                            </h5>
                            <p>In order to safeguard your data from unauthorised access, loss, or misuse, we enforce stringent security protocols. These measures consist of:
                            </p>
                            <ul>
                                <li>Encryption: The safeguarding of sensitive data during transmission and storage
                                </li>
                                <li>Secure Servers: The storage of your data on servers that are both secure and access-controlled.
                                </li>
                                <li>Access Controls: Limiting access to authorised personnel only
                                </li>
                                <li>Regular Security Audits: The monitoring and enhancement of our security systems
                                </li>
                                <li>Although we implement these measures, there is no guarantee that data transmission over the internet is entirely secure. We recommend the use of robust passwords and the implementation of measures to safeguard your personal information.
                                </li>
                            </ul>


                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">Information Exchange
                            </h5>
                            <p>We do not disclose your personal information, except in the following situations:
                            </p>
                            <h6 className="heading_h6 mb-3">Business Partners and Service Providers
                            </h6>
                            <p>We may disclose your information to reputable third-party service providers who aid in the operation of our website, the processing of payments, and the provision of services. These partners are obligated to maintain the confidentiality of your data.
                            </p>
                            <h6 className="heading_h6 mb-3">Legal Compliance
                            </h6>
                            <p>We may be obligated to disclose personal data in order to protect our legal rights, government authorities, or the law.
                            </p>

                            <h6 className="heading_h6 mb-3">Business Transfers
                            </h6>
                            <p>Your information may be transmitted as part of the business transaction if LogicLink Technology Service fzco undergoes a merger, acquisition, or sale. In such instances, we will inform you.
                            </p>


                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">Your Rights and Options
                            </h5>
                            <p>You are entitled to the following privileges with respect to your personal data:
                            </p>
                            <ul>
                                <li>Right to Access: Request a copy of the information we have on file about you
                                </li>
                                <li>Right to Correction: Revise or rectify inaccurate information
                                </li>
                                <li>Right to erasure: Subject to legal requirements, request the erasure of your data.
                                </li>
                                <li>Right to Restrictions: Regulate the manner in which we handle your data
                                </li>
                                <li>Right to Object: Refuse to provide consent for marketing communications
                                </li>
                                <li>The right to data portability: Your data will be delivered in a structured format.
                                </li>
                            </ul>
                            <p>Please reach out to us at office@logiclinkfzco.com to exercise these rights.
                            </p>

                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">Services and Links Provided by Third Parties
                            </h5>
                            <p>Links to third-party websites or services may be present on our website. We are not accountable for their privacy practices, and we recommend that you review their policies prior to disclosing any personal information.
                            </p>


                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">Tracking Technologies and Cookies
                            </h5>
                            <p>Cookies facilitate the enhancement of the user experience by:
                            </p>
                            <ul>
                                <li>Recalling user preferences
                                </li>
                                <li>Examining the efficacy and traffic of a website
                                </li>
                                <li>Providing pertinent advertisements
                                </li>
                            </ul>
                            <p>Cookies can be enabled or disabled by adjusting the settings of your browser. Nevertheless, certain website features may not function effectively in the absence of cookies.
                            </p>

                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">Policy for Data Retention
                            </h5>
                            <p>We maintain personal data for the duration of time required to fulfil the objectives delineated in this Privacy Policy, comply with legal obligations, resolve disputes, and enforce agreements. We securely delete or anonymise data when it is no longer necessary.
                            </p>

                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">Data Transfers on an International Scale
                            </h5>
                            <p>We have the ability to transfer data internationally, as LogicLink Technology Service fzco is a Dubai-based company. We guarantee that personal data is processed in accordance with the relevant data protection regulations in various jurisdictions.
                            </p>

                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">Modifications to This Privacy Policy
                            </h5>
                            <p>Periodically, we may revise this Privacy Policy to accommodate modifications in our procedures or legal obligations. The revised effective date will be displayed on this page in the event of any updates.
                            </p>

                            <h5 className="heading_h5 fw-bold mb-lg-4 mb-3">Contact Information
                            </h5>
                            <p>Please do not hesitate to reach out to us if you have any queries or concerns about this Privacy Policy:
                            </p>
                            <p>Email: office@logiclinkfzco.com
                            </p>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}              