import React, { useState } from 'react';
import { Link } from "react-router-dom"
import { sideBarToggle } from "../utils";
import Mobile from "./MobileMenu";

const Header = ({ header }) => {
  switch (header) {
    case 1:
      return <Header1 />;

    default:
      return <DefaultHeader />;
  }
};
export default Header;

const Header1 = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <header className="main-header header-two">
      <div className="header-upper">
        <div className="container clearfix">
          <div className="header-inner rel d-flex align-items-center justify-content-between">

            {/* Logo Section */}
            <div className="logo-outer">
              <div className="logo">
                <Link to="/">
                  <a>
                    <img
                      src="/assets/images/logos/logo-logiclink.png"
                      alt="Logo"
                      title="Logo"
                      height={70}
                    />
                  </a>
                </Link>
              </div>
            </div>

            {/* Navbar for Desktop & Mobile */}
            <div className="nav-outer clearfix">
              <nav className="main-menu navbar-expand-lg">

                {/* Mobile Toggle Button */}
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  aria-expanded={isOpen}
                  style={{ background: "none", border: "none" }}
                >
                  <div style={{ width: "20px", height: "3px", background: "#fff", margin: "5px 0" }}></div>
                  <div style={{ width: "30px", height: "3px", background: "#fff", margin: "5px 0" }}></div>
                  <div style={{ width: "30px", height: "3px", background: "#fff", margin: "5px 0" }}></div>
                </button>

                {/* Navbar Links */}
                <div className={`navbar-collapse collapse ${isOpen ? "show" : ""}`}>
                  <Menu />
                </div>
              </nav>
            </div>

            {/* Contact Button */}
            <div className="menu-btns d-none d-lg-block">
              <Link to="/contact">
                <a className="theme-btn style-three">
                  Contact <i className="fas fa-angle-double-right" />
                </a>
              </Link>
            </div>

          </div>
        </div>
      </div>
    </header>
  );
};

const DefaultHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="main-header header-three menu-absolute">
      {/* Header Upper */}
      <div className="header-upper">
        <div className="container clearfix">
          <div className="header-inner rel d-flex align-items-center justify-content-between">

            {/* Logo Section */}
            <div className="logo-outer">
              <div className="logo">

                <Link to="/">
                  <img src="/assets/images/logos/logo-logiclink-blue.png" alt="Logo" height={70} />
                </Link>
              </div>
            </div>
            <div className="clearfix">

              {/* Navbar Section */}
              <nav className="main-menu  navbar-expand-lg">

                {/* Hamburger Button for Mobile */}
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  aria-expanded={isOpen}
                  style={{ background: "none", border: "none" }}
                >
                  <div style={{ width: "20px", height: "3px", background: "#000", margin: "5px 0" }}></div>
                  <div style={{ width: "30px", height: "3px", background: "#000", margin: "5px 0" }}></div>
                  <div style={{ width: "30px", height: "3px", background: "#000", margin: "5px 0" }}></div>
                </button>
                {/* Collapsible Menu */}
                <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
                  <Menu />
                </div>

              </nav>
            </div>

            {/* Contact Button */}
            <div className="menu-btns d-none d-lg-block">
              <Link to="/contact" className="theme-btn">
                Contact <i className="fas fa-angle-double-right" />
              </Link>
            </div>

          </div>
        </div>
      </div>
    </header>
  );
};


const Menu = () => {
  return (
    <ul className="navigation clearfix mb-0 navbar-nav">
      <li className="dropdown">
        <Link to="/">Home</Link>

      </li>
      <li className="dropdown">
        <Link to="/about">About</Link>
      </li>
      <li className="dropdown">
        <Link to="/services">  Our Services</Link>
      </li>
      <li className="dropdown">
        <Link to="/blog">blog</Link>
      </li>
    </ul>
  );
};
