
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from "./Component/Page/Homepage"
import Services from "./Component/Page/Services"
import Blog from "./Component/Page/Blog"
import Contact from "./Component/Page/Contact"
import About from './Component/Page/About';
import BlogRenderer from './Component/Page/BlogRenderer';
import ScrollToTop from './Component/ScrollTop';
import PrivacyPolicy from './Component/Page/PrivacyPolicy';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog/:BlogDetails" element={<BlogRenderer />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
